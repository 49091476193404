import React, {useState, useRef, useEffect, useContext} from 'react';

import './styles.scss';
import Prescription from '../../Intake/Prescription';
import Brand from '../../Intake/Brand';
import CheckoutV3 from '../../Intake/CheckoutV3';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import DoctorV3 from '../../Intake/DoctorV3';
import PreventBottomScroll from "../../PreventBottomScroll";
import axios from "axios";
import {navigate} from "gatsby";
import HubbleOrderContext from "../../../context/hubbleOrderContext";
import {productsInvoice} from "../../Intake/Brand/productsList";
import {states} from "../../Intake/DoctorV3/mockedData";

const InvoiceOrderSection = ({ hubbleAccessories, id }) => {
  const { setBrand, setAccessories, setShippingAddress } = useContext(HubbleOrderContext);
  const stripePromise = loadStripe(process.env.GATSBY_STRIPE_KEY);
  const [invoiceData, setInvoiceData] = useState()
  const [initialValues, setInitialValues] = useState({
    prescription: {
      leftEye: {
        power: "",
        axis: "",
        addPower: "",
        cylinder: "",
      },
      rightEye: {
        power: "",
        axis: "",
        addPower: "",
        cylinder: "",
      },
    },
    checkout: {},
    doctor: {
      state: {},
      doctor: {}
    }
  })

  const steps = [
    { title: 'Brand', id: 1, ref: useRef(null) },
    { title: 'Rx', id: 2, ref: useRef(null) },
    { title: 'Doctor', id: 3, ref: useRef(null) },
    { title: 'Checkout', id: 4, ref: useRef(null) },
  ];

  useEffect(() => {
    const headers = { Authorization: `Bearer ${process.env.GATSBY_ADMIN_TOKEN}` }
    const url = `${process.env.GATSBY_ADMIN_URL}/api/customers/stripe/invoice_tool_auth/${id}`

    if (id && id !== "invoice") {
      axios.get(url, {headers}).then(async ({data: {invoice_data}}) => {
        if (invoice_data.created_at && (new Date(invoice_data.created_at).getTime() + 1000*60*60*24*3) < new Date().getTime()) {
          navigate("/")
          return
        }

        const products = productsInvoice
        const contacts = invoice_data.line_items.find(e => e.product_type === "contact_lens")
        const product = products.find(e => e.title.toLowerCase().includes(contacts?.product_name.toLowerCase()) || e.short_title?.toLowerCase().includes(contacts?.product_name?.toLowerCase()))
        const accessories = invoice_data.line_items
          .filter(e => e.product_type === "accessories")
          .map(e => hubbleAccessories.find(_e => e.product_name.toLowerCase().includes(_e.title.toLowerCase())))
          .map(e => ({
            image: e.productImage1,
            item_name: e.title,
            currency: "USD",
            discount: 0,
            item_brand: "Hubble",
            item_category: "accessory",
            variant_id: e.variantId,
            product_id: e.shopifyProductId,
            quantity: 1,
            price: e.price,
            line_item_type: "accessory"
          }))
        setAccessories(accessories)

        if (product) {
          setBrand(product)
        }

        const prescriptionInitialValues = {
          leftEye: {
            power: formatRx(invoice_data.left_power),
            cylinder: formatRx(invoice_data.left_cylinder),
            axis: invoice_data.left_axis,
            addPower: formatRx(invoice_data.left_additional_power),
          },
          rightEye: {
            power: formatRx(invoice_data.right_power),
            cylinder: formatRx(invoice_data.right_cylinder),
            axis: invoice_data.right_axis,
            addPower: formatRx(invoice_data.right_additional_power),
          },
        }

        const checkoutInitialValues = {
          invoiceId: id,
          email: invoice_data.email || "",
          first_name: invoice_data.first_name || "",
          last_name: invoice_data.last_name || "",
          address1: invoice_data.address1 || "",
          address2: invoice_data.address2 || "",
          city: invoice_data.city || "",
          province: invoice_data.province || "",
          phone: invoice_data.phone_number || "",
          phoneNumber: invoice_data.phone_number || "",
          zipcode: invoice_data.zipcode || "",
          country: invoice_data.country || "",
        }

        const doctorInitialValues = invoice_data.doctor_id ? {
          state: states.find(e => e.value === invoice_data.doctor_state),
          doctor: {
            id: invoice_data.doctor_id,
            value: invoice_data.doctor_id,
            name: invoice_data.doctor_name,
            clinic_name: invoice_data.doctor_clinic_name,
            state: states.find(e => e.value === invoice_data.doctor_state),
            postcode: invoice_data.doctor_postcode,
          }
        } : null

        if (!invoice_data.is_new_customer) {
          if (invoice_data.stripe_customer_id) {
            checkoutInitialValues.stripe_customer_id = invoice_data.stripe_customer_id
            checkoutInitialValues.paymentType = "stripe"
          }
        }

        setInitialValues({
          ...initialValues,
          doctor: doctorInitialValues,
          checkout: checkoutInitialValues,
          prescription: prescriptionInitialValues,
        })
        setInvoiceData(invoice_data)
        setShippingAddress({
          first_name : invoice_data.first_name,
          last_name: invoice_data.last_name,
          address: invoice_data.address1,
          city: invoice_data.city,
          province: invoice_data.state,
          zipcode: invoice_data.zipcode,
        })
      }).catch(e => {
        // console.log(e)
        navigate("/")
      })
    } else {
      navigate("/")
    }
  }, [id]);

  if (!invoiceData) return null

  return (
    <section
      className="invoice-order-section order-section-hydro-skyhy order-section-intake-v3"
      id="order-section"
    >
      <div className="content-container">
        <h2 className="section-title">Complete Your Order</h2>
        <span className="section-description">Fill out the following information to confirm and place your order.</span>
        <div className="steps-container padding-sides">
          <Brand
            steps={steps}
            setActiveSteps={() => {}}
            isHydroSkyhy
            isV3
            isInvoice
          />
          <Prescription steps={steps} setActiveSteps={() => {}} isV3 isInvoice initialValues={initialValues.prescription}/>
          <DoctorV3 steps={steps} setActiveSteps={() => {}} isV3 isInvoice initialValues={initialValues.doctor}/>
          <Elements stripe={stripePromise}>
            <CheckoutV3 steps={steps} isHydroSkyhy isIronclad initialValues={initialValues.checkout} isInvoice />
          </Elements>
        </div>
      </div>
      <PreventBottomScroll background="#E8EAFF" />
    </section>
  )
};

function formatRx(value) {
  let formatted = parseFloat(value).toFixed(2);
  if (formatted === "NaN") return NaN
  if (formatted === "0.00") return "0";
  return (formatted > 0 ? "+" : "") + formatted;
}

export default InvoiceOrderSection;
